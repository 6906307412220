.container {
  width: 100%;
}
.headingAsLink:hover::after {
  opacity: 1;
}
.headingAsLink::after {
  opacity: 0;
  color: var(--color-interactive-secondary-hover);
  content: ' #';
  transition: opacity 100ms ease;
}
