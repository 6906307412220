.link {
  font-weight: normal;
  text-decoration: none !important;

  &:hover {
    text-decoration: none;

    .title {
      text-decoration: underline;
    }
  }
}

.title {
  margin-top: var(--size-48);
  margin-bottom: var(--size-8);
  color: var(--color-interactive-primary);
}

.subtitle {
  margin-top: var(--size-8);
  margin-bottom: 16px;
  color: var(--color-content-secondary);
}

.content {
  width: 80%;
  color: var(--color-content-secondary);
  font-weight: var(--font-weight-regular);
}
