.container {
  border-radius: 3px;
  border: 1px solid var(--color-border-neutral);
  margin: 0 0 var(--size-24);
}

.header {
  background-color: var(--color-background-neutral);
  padding: var(--size-12);
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyButton {
  border: none;
}

.content {
  padding: var(--size-12);
  word-wrap: break-word;
  overflow: auto;

  pre {
    overflow: initial;
  }
}
