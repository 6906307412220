.link,
.link:active,
.link:hover {
  background-color: #fd6c35;
  border-radius: 1em;
  padding: 0.25em 2em;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}
