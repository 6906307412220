.container {
  width: 100%;
  min-height: 50vh;
  margin: 0 auto;

  @media (min-width: 769px) {
    width: 80%;
  }
  @media (min-width: 1200px) {
    width: 60%;
  }
}
