.container {
  margin: 6px 0;
}

.method {
  margin-right: var(--size-4);
  font-weight: var(--font-weight-semi-bold);
}

.link {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-regular) !important;
  color: #5d7079;
  color: var(--color-content-secondary);
  &:hover {
    color: var(--color-content-accent-hover);
  }

  .get {
    color: var(--color-content-accent);
  }
  &:hover .get {
    color: var(--color-content--accent-hover);
  }

  .delete {
    color: var(--color-content-negative);
  }
  &:hover .delete {
    color: var(--color-content-negative-hover);
  }

  .post {
    color: var(--color-content-positive);
  }
  &:hover .post {
    color: var(--color-content-positive-hover);
  }

  .put,
  .patch {
    color: var(--color-content-warning);
  }
  &:hover .put,
  &:hover .patch {
    color: var(--color-content-warning-hover);
  }
}
