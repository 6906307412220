.field {
  padding: var(--size-12) 0;
  border-top: 1px solid var(--color-border-neutral);
  font-size: var(--font-size-14);
  line-height: 1.42857;
}

.description {
  margin-top: var(--size-4);
  p {
    margin: 0; // .field controls the spacing
  }
}
