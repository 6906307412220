$singleColumnBreakpoint: 1024px;

.base {
  width: 100%;
  --content-width: 55%;
  --example-width: calc(100% - var(--content-width));
  --content-padding-right: var(--size-32);
  --title-padding-right: calc(var(--example-width) + var(--content-padding-right));
}

.section {
  composes: base;
  max-width: calc(var(--content-width) + var(--example-width));

  > h1,
  > h2 {
    width: 100%;
    align-self: flex-start;
    @media (min-width: $singleColumnBreakpoint) {
      padding-right: var(--title-padding-right);
    }
  }
}

.left {
  composes: base;
  @media (min-width: $singleColumnBreakpoint) {
    width: var(--content-width);
    padding-right: var(--content-padding-right);
  }
}

.right {
  composes: base;
  @media (min-width: $singleColumnBreakpoint) {
    width: var(--example-width);
    position: sticky;
    top: calc(var(--size-64) + var(--size-16));
  }
}
