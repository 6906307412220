.result {
  border-top: 1px solid var(--color-border-neutral);
}

.title {
  padding: var(--size-12) 0;
}

.breadcrumbs {
  margin: 5px 0px;
}
