.hero {
  position: relative;
  right: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  width: 100vw;
  margin-top: calc(var(--site-vertical-padding) * -1);
  margin-right: -50vw;
  margin-left: -50vw;
  margin-bottom: var(--size-32);
  padding-right: calc(50vw - 50%);
  padding-left: calc(50vw - 50%);
  background-color: var(--color-bright-green);

  @media (min-width: 769px) {
    margin-bottom: var(--size-64);
  }

  @media (max-width: 769px) {
    font-size: 4rem;
  }
}

.title {
  margin: 0;
  color: var(--color-forest-green);
  line-height: 1;
  max-width: 900px;

  @media (max-width: 769px) {
    font-size: 4rem;
    max-width: 600px;
  }
}
